@import './variables';

// Mixin that limits the element to $line-count lines of text.
// NOTE the only way to achieve this at present is with webkit vendor prefixed rules
// (which also work in Firefox for some reason). The `ignore next` comments prevent
// autoprefixer from stripping out the rules.
// https://github.com/postcss/autoprefixer/issues/776
@mixin clamp-lines($line-count) {
    /* autoprefixer: ignore next */
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: $line-count;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gem-card {
    border-radius: $card-border-radius;
    border: none;
    box-shadow: $box-shadow;
}
