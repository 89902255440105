$modal-padding: 20px;

.modal-content {
    background-color: #fff;
    padding: $modal-padding;
    width: 430px;
    margin: 72px auto;
    border-radius: $border-radius;
    border: none;

    >h5 {
        padding-bottom: 15px;
    }

    // For <svg>s that appear in the dialog title
    .title-icon {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }

    // For <hr>s that extend to the very edge of the modal
    .modal-full-hr {
        margin-left: -$modal-padding;
        margin-right: -$modal-padding;
    }

    // For action buttons at the bottom of the modal
    .no-gutters {
        margin-top: 32px;
    }
}

.modal-narrow .modal-content {
    width: 305px !important;
}

.modal-wide .modal-content {
    width: 800px !important;
    margin: 72px auto
}

// TODO find a way to achieve per-dialog overrides of modal-content without putting
// them in dialogs.scss
.mfa-modal .modal-content {
    width: 525px;
    padding: 20px 20px 15px 20px;
    min-height: 175px;

    h5 {
        margin-bottom: 25px !important;
    }
}

.purchase-modal {
    top: 30%;
    left: 18.5%;
    width: 80%;
    margin-left: -10%;
}

.purchase-modal .modal-content {
    width: 525px;
}

.progress-modal .modal-content {
    width: 400px;
}

.modal-dialog {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    min-width: 100%;
    transform: none !important;
    display: flex;
    // height: 100%;
    // align-items: center;
    // justify-content: center;
}

// ??? copy removed from wizard-inline.scss
// .modal-dialog {
//     padding: 0 !important;
//     margin: 0 !important;
//     width: 100%;
//     min-width: 100%;
//     transform: none !important;
// }

// todo remove this after we update the `tenantSecretGenerationFailed` modal is updated to NG2
.tenant-secret-generation-failed {
    z-index: 1052 !important;
}

.tenant-secret-generation-failed + .modal-backdrop {
    z-index: 1051 !important;
}
