@import 'src/colors';

// Global styles for the validation-icon

.form-group {
    .with-icons {
        padding-right: 22px;

        // NG1, NG2
        &.ng-hide + svg, &[hidden] + span > svg { // this stops the icons from appearing when the input is hidden
            display: none;
        }
    }

    input + svg.icon-svg.input-icon, input + span svg.icon-svg.input-icon {
        &.input-error {
            top: 33px;
            right: 20px
        }

        &.input-success {
            top: 32px;
            right: 20px;
        }
    }

    // when an input contains a (Bootstrap) prepending element
    .input-group-prepend + input + span.wrapper svg {
        top: 6px !important;
        right: 6px !important;
        z-index: 888;
    }

    // form-inline elements
    .form-inline input + svg.icon-svg.input-icon, .form-inline input + span svg.icon-svg.input-icon {
        top: 6px;
        right: 84px;
    }

    // The styles below apply to the elements in validation-icon's own template. Normally such style
    // would go in the component's own scss file. However since validation-icon hoists itself above its
    // normal position in the DOM, the auto-prefixed style rules generated by Angular won't apply to it.
    // Therefore, we have to declare its styles in a global stylesheet.

    svg.icon-svg.input-icon {
        position: absolute;

        &.input-error {
            fill: $invalid;
        }

        &.input-success {
            fill: $status-ok;
        }
    }

    div.validation-icon-helper { // useful for the odd case where the parent element has padding
        position: relative;

        svg.input-icon {
            &.input-error {
                top: 7px;
                right: 4px;
            }

            &.input-success {
                top: 8px;
                right: 5px;
            }
        }
    }
}
