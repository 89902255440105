// used for all services, not just HSM on Demand
.service-details {

    h5 {
        color: $gem-primary;
    }

    .service-name-header {

        .service-name-holder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

    }

}
