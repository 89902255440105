gem-subscriber-groups {

    .name-column {
        min-width: 76px;
    }

    .description-column {
        min-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .description-empty {
            opacity: 0.4;
        }
    }
}
