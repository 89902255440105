@import 'src/colors';
@import 'src/variables';
@import 'src/fonts';

// delete service modal
.modal-content {
    h5 {
        @include univia-pro-font;
        margin-bottom: 24px !important;
        font-size: 22px;
        color: $section-header-text-color;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .wizard-content-container {
        height:400px;
    }

    .alert-danger {
        background-color: $alert-bg;
        border-width: 0;
        color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .icon-svg {
            height: 20px;
            width: 20px;
            fill: #fff;
        }

        strong {
            padding-left: 7px;
        }
    }

    .delete-service-content {
        margin-top: 20px;
    }

    .service-input {
        margin-bottom: 10px;
    }
}

gem-delete-service {
    .no-gutters {
        margin-top: 0;
    }
}

.terms-of-service {
    overflow-y: auto;
    max-height: 244px; // how can we make this automatically match the height of its container?
    .tos-header {
        font-size: 20px;
        font-weight: 400;
    }
}

.tos-link:focus {
    outline: 0 !important;
}

.card-margins {
    margin: 16px 8px 0 8px;
}

.accept-terms-card {
    background: #f5f5f5;
    padding: 16px;
    border-radius: $border-radius;
    width: 100%;
    margin: 16px 0 0 0;

    input[type="checkbox"] {
        position: relative;
        top: 3px;
        margin: 0 8px;
    }
}
.modal-checkbox {
        position: relative;
        top: 3px;
        margin: 0 8px;
}

.field-primary-message {
    font-size: 11px;
}

.create-client:not(:focus)+.field-primary-message,
.create-client:not(:focus)+svg+.field-primary-message {
    visibility: hidden;
}

.create-client.ng-empty:invalid {
    box-shadow: none;
    border: 1px solid #ccc;

    &:focus {
        border-color: $gem-primary;
    }
}

.create-client.ng-empty+svg.input-error {
    visibility: hidden;
}

summary-step .nav-link {
    font-size:16px !important;
}
