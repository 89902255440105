$gem-primary: #00bbdd;
$gem-secondary: #00bbdd;
$gem-hover: #f1e9f1;
$gem-grey: #666666;
$alert-bg: #ff6666;
$status-ok: #50be20;
$invalid: #d01919;
$disabled: #ccc;
$gem-background: #f8f8f8;
$text-color: #666666;
$link-color: #0D7CA5 ;
$sub-heading-text-color:#515569;
$light-grey: #eeeeee;
$navigation-text-color: #d7d7d7;
$section-header-text-color: #1e202f;
$border-color: #ffff; /* used in tables and footer*/
$light-border-color: #e4e4e4; /* used in inline wizard and item separators */
$card-border-color: #fff; /* used in card border */

