@import 'src/variables';
@import 'src/fonts';

.container.main-col {
    @include fixed-main-content-nonresponsive;
}

$nav-text-color: #d7d7d7;
$nav-text-hover-color: #fff;
$nav-text-active-color: #fff;
$nav-height: 40px;
$nav-background-color:#0E1D3F;

nav.gem {
    font-size: 14px;
    height: $nav-height; // set height otherwise when items are not in the menu, main content may not line up with header correctly
    background: $nav-background-color;
    background: linear-gradient(101deg, #1a2856, #141534);

    a.nav-item {
        @include univia-pro-font;
        margin-left: 10px;
        text-decoration: none;
        text-transform: capitalize;
        color: $nav-text-color;

        &.active {
            border-top: 2px solid $gem-primary;
            line-height: 31px;
            color: $nav-text-active-color;
        }

        &:hover {
            color: $nav-text-hover-color;
        }
    }

    a.nav-item:first-of-type {
        margin-left: 0;
    }

    .navbar-nav {
        margin-left: 168px;

        & .nav-link {
            height: $nav-height;
            line-height: 36px;
            padding: 0 4px;
            margin-right: 24px;
        }
    }
}
