@import './colors';

$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';

// used for wrapping `details-panel` and for stand alone `data-tables`
$box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .14);

// thicker shadow shown on menus that are open, etc
$box-shadow-hover: 0 2px 5px 0 rgba(0, 0, 0, .24);

// border radius for tables and panels
$border-radius: 4px;

// border radius for cards
$card-border-radius: 4px;
$card-column-count: 5;

$footer-height: 36px;

$main-content-width: 1110px;

// This mixin can be used in cooperation with .container to keep the element at a fixed size
// for all screen widths. Until we have proper responsive layouts for tables, etc, we need to
// stick with a fixed-size content area.
@mixin fixed-main-content-nonresponsive {
    width: $main-content-width;
    max-width: $main-content-width;

    @media (min-width: 1px) {
        width: $main-content-width;
    }
}

//
// Bootstrap variables
//
$dropdown-link-color: $text-color;
$dropdown-link-hover-color: $text-color;
$dropdown-link-active-color: $text-color;
$dropdown-link-active-bg: $light-grey;
$input-btn-focus-box-shadow: none; // Remove the large "glow" from focused form fields
$table-color: $text-color;

// NOTE: the font we get from Angular material natively supports weights {300, 400, 500}.
// We must avoid using ANY other weights, as the browser will render them with interpolation,
// which can look ugly. Overriding Bootstrap's defaults to 500 avoids this, BUT when writing
// custom styles, we must similarly avoid using `bold` and `font-weight: 700` since they
// trigger the same problem.
//
// ** WRONG: `font-weight: bold`              **
// ** RIGHT: `font-weight: $font-weight-bold` **
// see https://jira.gemalto.com/browse/DPS-3935
$font-weight-bold: 500;
$font-weight-bolder: 500;
$headings-font-weight: 500;
