@import 'src/colors';

.loading-placeholder {
   position: fixed;
   overflow: hidden;
   width: 100%;
   height: 100%;

   display: flex;
   align-items: center;
   justify-content: center;
}