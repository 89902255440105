@import 'src/colors';

.reset-user .alert-success {
  background-color: #fff;
  color: $gem-primary;
  border: 1px solid $gem-primary;
  display: flex;
  align-items: center;
  padding: .75rem 2rem .75rem .75rem;

  .icon-svg {
    height: 35px;
    width: 35px;
    fill: $gem-primary;
    margin-right: 10px;
  }
}

.table {
  .name-column {
    max-width: 185px;
    overflow-wrap: break-word;
  }
  .email-column {
    max-width: 185px;
    overflow-wrap: break-word;
  }
  .description-column {
    max-width: 237px;
    overflow-wrap: break-word;
  }
}

create-subscriber-group {

    .description-row {
        margin-bottom: 7px;
    }

    textarea {
        // Round rem units used by bootstrap to nearest pixel to avoid cheese
        // https://github.com/twbs/bootstrap/issues/19804
        padding: 5px 5px 5px 8px;
    }

    .chars-remaining {
        margin-bottom: 25px;
    }

}
