@import "./ghost-animation";

svg.ghost-item {
    fill-opacity: 0;

    use {
        display: none;
    }
}

.ghost-item {
    background-color: #fff;
    border-bottom: 6px solid white; // spacing between blocks
    border-radius: 4px 4px 4px 4px;
    @include animate-ghost-line;
    color: transparent;
}

.ghost-item.empty-block::after {
    content: "&nbsp;"
}
