@import 'src/fonts';

// Styling overrides for Angular Bootstrap "Tabs"
.tab-tabset .nav-pills,
ngb-tabset[type=pills] .nav-pills {
    $border-bottom: 2px solid; // used for underlining the tabs

    margin-bottom: 20px;

    // Make the grey line beneath inactive tabs extend to the container width.
    // The strategy here takes advantage of the fact that bootstrap uses display:flex on nav-pills.
    // By adding an after pseudo-element as the last child of nav-pills and giving it flex-grow:1,
    // it will occupy the remaining horizontal space in the container.
    &:after {
        content: '';
        border-bottom: $border-bottom $light-grey;
        flex-grow: 1;
        margin-top: -2px;
    }

    .nav-link {
        @include univia-pro-font;
        background-color: transparent !important;
        color: $gem-primary !important;
        font-size: 22px;
        border-bottom: $border-bottom #fff;
        border-radius: 0;
        padding: 0.5em 1em 0.5em 1em;
        white-space: nowrap;
        text-decoration: none;
        padding-top:0;

        &.active {
            border-bottom: 2px solid $gem-primary;
            border-radius: 0;
        }

        &:not(.active) {
            color: $section-header-text-color !important;
            border-bottom: $border-bottom $light-grey;
        }
    }
}
