@import 'src/colors';
@import 'src/variables';
// https://confluence.gemalto.com/display/DPAAS/DPoD+Style+Guide#DPoDStyleGuide-ButtonStyles

.btn {
    line-height: 16px;
    padding: 5px 20px;
    font-size: small;
    cursor: pointer;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.btn.disabled,
.btn:disabled {
    opacity: .4 !important;
}

// This spaces out buttons that come one right after another in a row
.btn+.btn,
.btn+a .btn {
    margin-left: 16px;
}

// Primary button
.btn-next {
    color: #fff;
    background-color: $gem-primary;
    border-color: $gem-primary;
    border-radius: 2px;

    &:hover:not(:disabled) {
        color: #fff;
        background-color: $gem-secondary;
        border-color: $gem-secondary;
    }
}

// New look and feel of the primary button. Later we have to get rid of this class and transfer all rules to btn-next
.btn-next-new {
    font-size: 15px;
    font-weight: $font-weight-bold;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 4px;
}

.btn-inside-card {
    width: 88px;
    &-selected {
        font-weight: 700;
    }
}

// Secondary button
.btn-default {
    background-color: #fff;
    border-color: $border-color;
    color: $gem-primary;
    border-radius: 2px;

    &:hover {
        background-color: $light-grey;
        border: 1px solid $light-grey;
    }

    &.disabled,
    &:disabled {
        opacity: .4 !important;
    }
}

// used for the trash can icon
.icon-inside-icon {
    display: inline-block; // fixes issue with firefox and sometimes pushing the inner icon outside

    svg {
        fill: #ccc;
        background-color: #fff;
        border-radius: 4px;
        cursor: pointer;
    }

    svg.outer-icon {
        height: 20px !important;
        width: 20px !important;
    }

    svg.inner-icon {
        fill: $gem-primary;
        background-color: transparent;
        position: absolute;
        width: 12px !important;
        height: 12px !important;
        top: 3px !important;
        left: 4px !important;
    }

    svg.copy-icon-header {
        fill: $gem-primary;
        background-color: transparent;
        margin-right: 25px;
    }
}

// span.grid-action, div.grid-action {
.grid-action {
    cursor: pointer;
    color: #6c286c;
    position: relative;

    &.disabled {

        // used to disable actions inside an actions-column
        svg {
            cursor: default !important;
            opacity: 0.4;
        }
    }

}

// todo, fancy animations like sliding in out

/*<label class="label-status" [ngClass]="active ? 'on' : 'off'">
    <span class="off">OFF</span>
    <span class="on">ON</span>
</label>*/
.label-status {

    border-radius: $border-radius;
    padding: 3px;
    font-weight: $font-weight-bold;
    height: 18px;
    font-size: 9px;
    text-align: center;

    span {
        position: relative;
        top: 1px;
        display: inline-block;
    }

    &.on {
        background-color: $gem-primary;
        color: #fff;

        .off {
            display: none;
        }
    }

    &.off {

        background-color: #e4e4e4;
        color: $gem-grey;

        .on {
            display: none
        }
    }
}

// used as well in gangplank
.fake-link-button {
    border: 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
    text-decoration: underline;
    cursor: pointer;
    color: #49a8d5;
    padding: 0;
}

// used as well in gangplank
.fake-link-button:hover {
    color: #0d7ca5;
}
