// $gem-primary: #6c286c;
// $gem-secondary: #a06da0;
// $disabled: #ccc;

@import 'src/colors';
@import 'src/variables';

:host,
service-card { // service-card rule is only for AJS cards
  width: 280px;
  height: 128px;
  box-sizing: border-box;
  text-align: left;
  padding: 1px;
  border: none;
  box-shadow: $box-shadow;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
  background:#fff;

  &[disabled] {
    cursor: default;
    color: $disabled;

    svg {
      fill: $disabled;
    }
  }

  &:hover:not([disabled]) {
    // border: 2px solid $gem-secondary;
    box-shadow: $box-shadow-hover;
    // padding: 0;
  }

  .gem-card {
    // make the entire container a click target
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border:none;

    .svg-wrap {
      border-width: 0;
      flex-basis: 30%;
      padding: 0 15px 0 15px;

      svg, img {
        width: 64px;
        height: 64px;
      }
    }

    .gem-card-text {
      overflow-wrap: break-word;
      font-size: 12px;
      line-height: 16px;
      padding-right: 15px;
      color: #666;
      max-height: 100px;
      overflow: hidden;
      flex-basis: 70%;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
      }
    }
  }
}
