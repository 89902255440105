// basic properties to apply to the whole application

// NOTE: some of these styles should be moved down into more specific components

@import 'src/fonts';

html {
    height: 100%;
    overflow-y: scroll;
}

body {
    height: 100%;
    font-size: 13px;
    color: $gem-grey;
    background: $gem-background;
    padding-right: 0 !important;
}

html, body {
    @include roboto-font;
}

.main.logged-in {
    // When the user is logged in the footer will appear. Set a bottom margin so that
    // no content can appear under the footer.
    margin-bottom: $footer-height;
}

.container-fluid {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    height: calc(100% - 103px);
}

.head-nav-holder {
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100%;
    background: linear-gradient(101deg, #1a2856, #141534);
}

.color-primary {
    color: $gem-primary !important;
}

.color-secondary {
    color: $gem-secondary !important;
}

.color-disabled {
    color: $disabled !important;
}

.color-success {
    color: $status-ok !important;
}

.color-fail {
    color: $invalid !important;
}

.dropdown-menu {
    font-size: .85rem;
    background: #fff;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    box-shadow: $box-shadow-hover;
    padding: 0;

    :first-child {
        border-radius: 4px 4px 0 0;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
}

.dropdown-item {
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    -webkit-tap-highlight-color: $text-color;
    width: auto;

    &:hover {
        cursor: pointer;
    }
}


.details-panel {
    border: 1px solid #ccc;
    margin-bottom: 25px;
    color: #666;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    &.main-panel {
        border: none;
    }

    .details-panel-section {
        padding: 20px;
        // border-bottom: 1px solid #ccc;
        background: white;
        border-radius: $border-radius;

        .border-holder {
            // if a `data-tables` component is inside a `details-panel-section`, we don't have a border around the table
            // this should be fine for all cases
            box-shadow: none;

        }
    }

    .details-panel-section:last-child {
        border-bottom: 0;
    }

    .details-header {
        // border-bottom: 1px solid $disabled !important;
        background-color: rgb(250, 250, 250);
        padding: 5px 18px 5px 18px;
        margin-bottom: 0;
        color: #666;
        border-bottom: 0;
        // Keep the same border-radius as our containing panel to ensure that corners
        // look sharp at the top left and top right where they intersect the containers'
        // borders.
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
}

svg {
    fill: $gem-primary;

    &.delete {
        transform: translate(10%, 20%); // For centering the Delete icon vertically
    }

    // Firefox workaround https://stackoverflow.com/a/38124867/3394770
    use svg {
        fill: inherit;
    }

    &.success {
        fill: $status-ok;
    }

    &.danger {
        fill: $invalid;
    }
}

// bootstrap overrides

.form-control {
    padding: .3rem .3rem .3rem .5rem;
    color: $gem-grey;
    font-size: inherit;

    &:focus {
        border-color: $gem-primary;
    }

    // some form-control elements border-radius is overwritten by Bootstrap (ex. input-group-prepend), this ensures all inputs will have the same border-radius
    //border-radius: 4px !important;
}
select.form-control {
  &[size],
  &[multiple] {
    height: $input-height;
  }
}

// hopefully can be reused to reduce duplicate styling
.box {
    border: 1px solid #ccc;
}

.form-control::placeholder {
    color: #bebebe; // TODO color variable for this?
}

.dropdown-toggle::after {
    display: none !important; // hide the caret that uib-dropdown adds beside dropdown triggers
}

// custom stuff

// HEADERS

.gem-header {
    color: $section-header-text-color;
    margin-bottom: 6px;

    &.section-header {
        @include univia-pro-font;
        display: block;
        font-size: 22px;
        margin-bottom: 16px;
    }

    &.section-subheader {
        font-size: 18px;
    }

    &.panel-header {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 32px;
    }

    &.tile-category-header {
        font-size: 16px;
        margin: 8px 0 8px 0;
        color: $sub-heading-text-color;
    }
}

.sub-header {
    font-size: 16px;
    color: $sub-heading-text-color;
}

// slide-in animation for details views

.slide-left.ng-enter,
.slide-left.ng-leave {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform .7s ease-in-out;
}

.slide-left.ng-enter {
    z-index: 101;
    transform: translateX(100%);
}

.slide-left.ng-enter.ng-enter-active {
    transform: translateX(0);
}

.slide-left.ng-leave {
    z-index: 100;
    transform: translateX(0);
}

.slide-left.ng-leave.ng-leave-active {
    transform: translateX(-100%);
}

// link colours and styles
#{$a-tags},
#{$a-tags-hover} {
    color: $link-color;
    text-decoration: none;

    .section-header {
        color: $section-header-text-color;
        text-decoration: none;
    }

    .section-header-detail {
        color: $gem-primary;
    }
}

.page-item .page-link {
    color: $gem-primary;
}

.page-item.active .page-link {
    color: #fff;
    background-color: $gem-primary;
    border-color: $gem-primary;
}

.invalid {
    color: $invalid;
}

textarea {
    resize: none; // textareas by default will not be able to be resized
}

textarea.ng-invalid,
textarea.ng-invalid:focus,
input.ng-dirty.ng-invalid,
select.ng-dirty.ng-invalid {
    border-color: $invalid;
    // box-shadow: 0 0 8px $invalid;
}

.gem-link {
    display: block;
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// when you need `gem-link` styling but don't want to use a hyperlink
// for example using links inside ngbTabset
.fake-link {
    @extend .gem-link;
    display: inline;

    &:hover {
        text-decoration: none;
    }
}

.error-message {
    // to be used with ng-messages
    font-size: 11px;
}

// to expand off https://v4-alpha.getbootstrap.com/utilities/vertical-align/
.align-sub {
    vertical-align: sub;
}

// this is to fix an issue with ngAnimate and ngHide
// https://stackoverflow.com/questions/26938021/angular-ng-if-or-ng-show-responds-slow-2second-delay
.ng-hide.ng-hide-animate {
    display: none !important;
}

hr {
    text-align: left; // For IE, Opera
    margin-left: 0; // For Chrome, Firefox and Safari
    margin-top: 0;
    margin-bottom: 20px;
}

.page {
    // used for main-content.  Main content should be wrapped in this selector
    padding-top: 24px;
    height: 100%;
    margin-bottom:4em;
}

.pre {
    white-space: pre;
}

.word-wrap-break {
    overflow-wrap: break-word;
}

.word-break-break-all {
    word-break: break-all;
}

.main-viewport-holder {
    padding-top: 107px;
    display: flex;
    margin-top: 0;
    top: 0;
    position: relative;
    min-height: 100%;
    height: auto;

    &-fullscreen {
        position: fixed;
        justify-content: center;
        min-width: 100%;
        padding-top: 0;
        left: 0;
    }

    >.main-ui-view {
        width: 100%;
    }

    // Bootstrap overrides to disable responsive sizing of the container class for the main viewport.
    // Until we have proper responsive layouts for tables, etc, we need to keep this container at
    // a fixed size, and force users to scroll if the viewport isn't wide enough to fit it.
    &.container {
        @include fixed-main-content-nonresponsive;
    }
}

.panel-width,
hr {
    max-width: $main-content-width;
}

/**
  Angular multi select dropdown styling overrides
 */
div[ng-dropdown-multiselect] {
    padding: 0;
    height: 31.6px;

    .dropdown-multiselect {
        width: 100%;
        height: 100%;

        >div {
            width: 100%;
        }

        button {
            background: none;
            padding-left: 8px;
            padding-top: .3em;
            padding-bottom: .3em;
            margin: 0;
            width: 100%;
            height: 100%;
            text-align: left;
            color: $gem-grey;

            span.caret {
                display: inline-block;
                background: #fff;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid #666;
                top: 50%;
                position: absolute;
                right: 10px;
            }
        }

        ul {
            top: 29px;
            border-top: 0;
            margin: 0;
            padding: 0;
            font-size: 13px;
            width: 100%;

            .dropdown-header {
                padding: 0;
            }

            li {
                cursor: pointer;
                display: block;

                input {
                    font-size: 13px;
                    border-color: rgba(0, 0, 0, .15);
                    border-left: 0;
                    border-right: 0;
                }
                input:focus {
                    box-shadow: none;
                }

                a {
                    padding: 4px;
                    display: block;
                    color: $gem-grey;
                    text-decoration: none;

                    &:hover {
                        background-color: $gem-hover;
                    }
                }
            }
        }
    }
}

.no-resource-prompt {
    color: $gem-grey;
}

.fieldset-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.textarea-container {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding: 0.5px 0.5px 0.5px 0;

    &:focus {
        border: $gem-primary;
        border-radius: 0.25rem;
    }
}

.wizard-modal .details-panel {
    box-shadow: none !important
}


// Bootstrap "cards" overrides
.card .card-header {
    height: 48px;
    background-color: #f9f9f9;
    border-bottom: none;
}


// todo move into own Bootstrap extends SCSS file
.list-style-none {
    list-style: none;
}

.gears-background {
    width: 400px;
    height: 400px;
    position: relative;
    margin: -30px 0 0 -50px;
    pointer-events: none;

    // Use mask technique to "fill" background SVG with a color
    // Reference: https://codepen.io/noahblon/post/coloring-svgs-in-css-background-images
    background-color: rgba($section-header-text-color, .1);
    mask-image: url(./images/Icon_Services-36.svg);
    mask-repeat: no-repeat;
    mask-size: 400px 400px;
}

// CARDS
.card-grid {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

// ICONS
.icon-svg {
    height: 16px;
    width: 16px;

    .checkmark {
        fill: $status-ok;
    }

    &.highlight:hover {
        fill: $gem-primary;
        cursor: pointer;
    }

    &.question {
        fill: $navigation-text-color;
    }
}

.tenant-name-whitespace {
    white-space: pre-wrap;
}
