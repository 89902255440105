@import 'src/colors';

salesforce-wizard {

    // .wizard-content-container {
    //     margin: 0 40px;
    // }

    .card {
        margin: 0 !important;
    }

    .card-margins {
        margin: 10px 0 0 0 !important;
    }

    .card-box { // override the card styling
        padding: 20px;
        border: 1px solid $gem-grey;
    }

    spinner .spinner {
        display: inline-block;
        height: 17px;
        width: 17px;
        vertical-align: middle;
    }

    .card.summary .row {
        margin-bottom: 20px;
    }

    .col-border-left {
        border-left: 1px solid #666;
    }

}

salesforce-key-broker-details {
    .uib-tab-heading {
        margin-bottom: 0;
        border-bottom: 0;
    }

    .denied-access {
        background-color: #f9f9f9;
        border: 1px solid #ccc;
    }

    .has-button .message {
        line-height: 28px;
    }

    .filter-select {
        height: 27px !important;
    }

}
